<template>
    <main class="page campaigns-user campaigns-user-list">
        <div class="page__body campaigns-user__body">
            <div class="first-screen offset-section_small first-screen_full">
                <div class="_container">

                    <!-- ==================================== BREADCRUMBS ========================================= -->
                    <BreadCrumbs :links="[{ title: 'Campaigns' }]" />
                    <!-- ==================================== BREADCRUMBS ========================================= -->

                    <div class="first-screen__body">
                        <div class="first-screen__left">
                            <div class="page-heading page-heading_colors">
                                <h1>
                                    <span>Campaigns <em>Roadmap</em></span>
                                </h1>
                            </div>

                            <div class="page-description _icon-arrow-2 page-description_offset" ref="page-scroll-root">
                                <p>
                                  We’re sharing our roadmap to let our players and partners know there’s plenty of excitement and fun on the horizon. With our games and promotions, we guarantee ever-growing thrills and unforgettable gaming experiences!
                                </p>
                            </div>
                        </div>
                        <div class="first-screen__right"></div>
                    </div>
                </div>
            </div>

            <div class="_container">
                <CampaignGrid :items=campaigns v-if="campaigns.length > 0" />
                <div v-if="!campaigns.length && !loading">
                    There are no campaigns in the list
                </div>
            </div>

        </div>
    </main>
</template>

<script>

import BreadCrumbs from "@/components/breadcrumbs";
import { appFetchJSON } from "@/lib/request_utils";
import CampaignGrid from "@/pages/campaigns/campaign_grid";
import { scrollToElement } from "@/lib/common_utils";

export default {
    name: "Campaigns",
    components: {
        BreadCrumbs,
        CampaignGrid,
    },

    data() {
        return {
            campaigns: [],
            totalPages: 0,
            pageNum: 1,
            loading: false,
        }
    },

    setup() {

    },

    mounted() {
        this.getData();
    },

    watch: {
        '$route.query'() {
            this.getData();
        }
    },

    methods: {
        async fetchCampaigns() {
            const options = {
                method: 'GET',
                params: {
                    page_num: this.pageNum,
                    items_per_page: 12,
                }
            };
            return appFetchJSON(`/campaigns`, options)
                .then(async res => {
                    const data = res.data;
                    const campaigns = data['campaigns'];
                    const promises = [];
                    campaigns.forEach(c => {
                        const routerResolveParams = {
                            name: 'client-area-campaigns-campaign',
                            params: { name: c.slug }
                        };
                        c.url = this.$router.resolve(routerResolveParams).fullPath;

                        // const promise = new Promise((resolve, reject) => {
                        //     let logoImage = c.logoImage = new Image();
                        //     logoImage.onload = resolve;
                        //     logoImage.onerror = resolve;
                        //     logoImage.src = c.logo;
                        // });
                        // promises.push(promise);
                    });
                    return Promise.all(promises).then(() => data);
                })
                .then(data => {
                    this.campaigns = data['campaigns'];
                    this.totalPages = data['total_pages'];
                    console.log(this.campaigns)
                });
        },

        onPageClick(pageNum) {
            let routeData = {
                name: 'client-area-campaigns',
                query: { ...this.$route.query || {}, page: pageNum }
            };
            this.$router.replace(routeData);
        },

        async getData() {
            this.loading = true;
            this.pageNum = Number(this.$route.query.page) || 1;
            return this.fetchCampaigns().then(() => {
                this.$nextTick(() => {
                    this.loading = false;
                });
            });
        },

        onSelectBoxClick() {
            this.isStatusesContainerActive = !this.isStatusesContainerActive;
        },

        onResize() {
            if (this.resizeTimeout) {
                clearInterval(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                this.resizeTimeout = undefined;
                this.getData().then(() => {
                    const pageNum = this.pageNum || 0;
                    const totalPages = this.totalPages || 0;
                    if (pageNum > totalPages) {
                        this.onPageClick(totalPages)
                    }
                });
            }, 600);
        },
    }
}
</script>

<style scoped></style>