<template>
    <span v-html="itemHTMLValue"></span>
</template>

<script>
export default {
    name: 'PrizeDropPrizesComponent',
    props: {
        prize: {
            type: Object,
            default: {},
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },
    computed: {
        prizeTypeValueProcessors() {
            return {
                'MONEY': this.prizeTypeValueProcessorMoney,
                'CUSTOM': this.prizeTypeValueProcessorCustom,
                'BET_MULTIPLIER': this.prizeTypeValueProcessorBetMultiplier,
            }
        },

        itemHTMLValue() {
            const prize = this.prize;
            const prizeType = prize.type;
            const prizeTypeValueProcessor = this.prizeTypeValueProcessors[prizeType];
            if (!prizeTypeValueProcessor) {
                return '';
            }

            return prizeTypeValueProcessor(prize);
        }
    },
    methods: {
        getTwoDigestFloat(value) {
            return (parseFloat(value) / 100).toFixed(2);
        },

        prizeTypeValueProcessorMoney(item) {
            const value = this.getTwoDigestFloat(item.value);
            return `${item.currency}&nbsp;${value}`;
        },

        prizeTypeValueProcessorCustom(item) {
            return item.name;
        },

        prizeTypeValueProcessorBetMultiplier(item) {
            return `x${item.bet_multiplier}`;
        }
    }
}
</script>