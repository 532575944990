<template>
    <main>
        <div class="ranks-filters">
            Geo Tag:
            <select class="ranks-select" @change="onSelectChoice($event.target.value)"
                v-model="selected">
                <option v-for="item in geoTags" :value="item">{{item}}</option>
            </select>
        </div>
        <div objname="games_ranks" class="" v-if="gamesListSpreadsheet !== null">
            <SummaryGrid
                :key="gamesListSpreadsheetKey"
                :options="gamesListSpreadsheet"
                :user="user" />
        </div>
    </main>
</template>

<script>

import {appFetch} from "@/lib/request_utils";
import SummaryGrid from "@/pages/summary/grid.vue";
import {isMobile} from "@/lib/browser";

// "cssClass": "restriction-cell-name"
const useFixedColumns = !isMobile.any();

const GEO_TAGS = [
    "CANGAROO",
    "Central CIS",
    "Certified",
    "CN",
    "DACH EU",
    "East Asia",
    "FrenchCaPt",
    "IT",
    "JP",
    "KR",
    "RU",
    "RU CIS",
    "South-East Asia",
    "VN",
    "West EU",
    "WW",
]

let fullSchema = [
    {
        "name": "final_rank",
        "type": "text",
        "title": "pk",
        "width": "0"
    },
    {
        "name": "game_name",
        "type": "text",
        "title": "Game ID",
        "width": "180",
    },
    {
        "name": "points",
        "type": "text",
        "title": "Points",
        "width": "250",
    },
    {
        "name": "bets",
        "type": "text",
        "title": "Bets, %",
        "width": "80",
    },
    {
        "name": "players",
        "type": "text",
        "title": "Players, %",
        "width": "80",
    },
    {
        "name": "vips",
        "type": "text",
        "title": "VIPs, %",
        "width": "80",
    },
    {
        "name": "rounds",
        "type": "text",
        "title": "Rounds, %",
        "width": "80",
    },
    {
        "name": "ggr",
        "type": "text",
        "title": "GGR, %",
        "width": "80",
    },
    {
        "name": "avg_bet",
        "type": "text",
        "title": "Average Bet, %",
        "width": "80",
    },
    {
        "name": "avg_rounds",
        "type": "text",
        "title": "Average rounds, %",
        "width": "80",
    }
]

export default {
    name: 'SummaryTabRanks',
    components: {SummaryGrid},
    props: {
        user: {
          type: Object,
          default: {}
        }
    },
    data() {
        return {
            selected: 'WW',
            geoTags: [...GEO_TAGS],
            currentFilter: 'WW',
            gamesListSpreadsheet: null,
            gamesListSpreadsheetKey: 0,
            gamesList: null
        }
    },
    mounted() {
        this.loadGamesList()
    },
    methods: {
        loadGamesList() {
            const date = new Date();
            date.setMonth(date.getMonth() - 1)

            appFetch(`/summary/ranks/`, {
              method: 'GET',
              credentials: 'include',
              params: {
                  month: date.toISOString().split('T')[0],
                  geotag: this.currentFilter
              }
            })
                .then(response => response.json())
                .then(response => {
                    const currentDate = new Date();
                    currentDate.setMonth(currentDate.getMonth() - 1)
                    const currentMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate)
                    const currentYear = currentDate.getFullYear()

                    const schema = this.prepareSchema(response)
                    this.gamesListSpreadsheet = {
                        title: `${this.currentFilter} / ${currentMonth} ${currentYear}`,
                        csvFileName: 'games_ranks',
                        pkColumn: 'final_rank',
                        fixedColumnsNumber: useFixedColumns ? 1 : 0,
                        activeTab: 'games_ranks',
                        gridID: 'games_ranks',
                        data: schema.games,
                        columns: schema.columns,
                        nestedHeaders: schema.nestedHeaders,
                        updateTable: function (instance, cell, col, row, value, id) {
                            const columnName = schema.columns[col]?.name;

                            if (columnName === 'game_name') {
                                cell.innerHTML = value
                                return
                            }

                            let parsedValue = value !== null && value !== undefined && !isNaN(value) ? parseFloat(value) : null;

                            if (columnName === "points") {
                                parsedValue = parsedValue.toFixed(2)
                            } else {
                                parsedValue = parsedValue.toFixed(1)
                            }

                            if (columnName === "points") {
                                cell.innerHTML = ''

                                const maxValue = Math.max(...schema.games.map(game => parseFloat(game[columnName] || 0)))
                                let percentage = maxValue > 0 && parsedValue !== null ? (parsedValue / maxValue) * 100 : 0
                                percentage = Math.max(percentage, 0)

                                const totalRows = schema.games.length
                                const opacity = 1 - ((row / (totalRows - 1)) * (1 - 0.2))

                                const container = document.createElement('div')
                                container.style.display = 'flex'
                                container.style.alignItems = 'center'

                                const progressBarWrapper = document.createElement('div')
                                progressBarWrapper.style.flex = '1 1 auto'

                                const progressBar = document.createElement('div')
                                progressBar.style.width = `${percentage}%`
                                progressBar.style.height = '10px'
                                progressBar.style.backgroundColor = `rgba(18, 77, 147, ${opacity})`

                                progressBarWrapper.appendChild(progressBar)

                                const text = document.createElement('span')
                                text.style.marginLeft = '8px'
                                text.textContent = `${parsedValue > 0 ? parsedValue : 0}`

                                container.appendChild(progressBarWrapper)
                                container.appendChild(text)

                                cell.appendChild(container)
                            } else {
                                if (parsedValue === null || isNaN(parsedValue)) {
                                    cell.innerHTML = '<span class="empty-cell"></span>';
                                } else {
                                    cell.innerHTML = parsedValue;
                                }
                            }
                        }
                    }

                    this.gamesListSpreadsheetKey++;
                })
        },
        prepareSchema(data) {
            const {columns, games} = data.data;
            let allowedSchema = [],
                nestedHeaders = [];

            const sortedGames = games.sort((a, b) => b.points - a.points);

            fullSchema.forEach(cell => {
                if (columns.includes(cell.name)) {
                    allowedSchema.push({
                        ...cell,
                    });
                    nestedHeaders.push({
                        colspan: 1,
                        title: "&nbsp;",
                        width: cell['width']
                    });
                }
            });

            return {
              columns: allowedSchema,
              nestedHeaders,
              games: sortedGames,
            }
        },
        onSelectChoice(item) {
            this.currentFilter = item
            this.loadGamesList()
        },
    }
}
</script>