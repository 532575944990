<template>
  <main class="summary-page" :style="{'--content-margin-top': this.marginTop}">
    <div ref="header" class="summary-page__header">
      <a href="/" class="logo"></a>
      <div class='tabs no-print'>
        <ul>
          <li href="games"
              @click="onTabClick"
              :class="{'active': tab === 'games'}">GAMES</li>
          <li href="ranks"
              @click="onTabClick"
              :class="{'active': tab === 'ranks'}">RANKS</li>
          <li href="certificates"
              @click="onTabClick"
              :class="{'active': tab === 'certificates'}">CERTIFICATES</li>
          <li href="languages"
              @click="onTabClick"
              :class="{'active': tab === 'languages'}">LANGUAGES</li>
          <li href="currencies"
              @click="onTabClick"
              :class="{'active': tab === 'currencies'}">CURRENCIES</li>
          <li href="restrictions"
              @click="onTabClick"
              :class="{'active': tab === 'restrictions'}">RESTRICTIONS</li>
        </ul>
      </div>
      <div class="_header-ext" :class="{'_active': tab === 'ranks'}">
          <div class="legend-panel">
              <div class="ranks-header">
                  <p>
                      Based on Compound Points derived from 7 KPIs.Calculated by summing up game ranks across each KPI,
                      taking to account weight of KPI group.Compound Points - Higher is Better.
                  </p>
                  <div class="ranks-labels">
                    <span>Bets Weight - 130%</span>
                    <span>Players Weight - 20%</span>
                    <span>VIPs Weight - 10%</span>
                    <span>Rounds Weight - 50%</span>
                    <span>GGR Weight - 100%</span>
                    <span>Average Bet Weight - 50%</span>
                    <span>Average Rounds Weight - 50%</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="_header-ext" :class="{'active': tab === 'certificates'}">
        <div class="legend-panel">
          <div>
            <span class="color-logo present"></span><span>available</span>
          </div>
          <div>
            <span class="color-logo absent"></span><span>not available</span>
          </div>
        </div>
      </div>
      <div class="_header-ext" :class="{'_active': tab === 'languages'}">
        <div class="legend-panel">
          <div>
            <span class="color-logo present"></span><span>available</span>
          </div>
          <div>
            <span class="color-logo absent"></span><span>not available</span>
          </div>
        </div>
      </div>
      <div class="_header-ext" :class="{'_active': tab === 'restrictions'}">
        <div class="legend-panel">
          <div>
            <span class="color-logo can-be-lifter"></span><span>This restriction can be lifted, please contact our AM team</span>
          </div>
          <div>
            <span class="color-logo cant-be-lifter"></span><span>This restriction cannot be lifted</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-content">
      <div id="games" :class="{'active': tab === 'games'}">
        <SummaryTabGames v-if="shown.games" :user="user"/>
      </div>

      <div id="ranks" :class="{'active': tab === 'ranks'}">
        <SummaryTabRanks :user="user" />
      </div>

      <div id="certificates" :class="{'active': tab === 'certificates'}">
        <SummaryTabCertificates v-if="shown.certificates" :user="user"/>
      </div>

      <div id="languages" :class="{'active': tab === 'languages'}">
        <SummaryTabLanguages v-if="shown.languages" :user="user"/>
      </div>

      <div id="currencies" :class="{'active': tab === 'currencies'}">
        <SummaryTabCurrencies v-if="shown.currencies" :user="user"/>
      </div>
      <div id="restrictions" :class="{'active': tab === 'restrictions'}">
        <SummaryTabRestrictions v-if="shown.restrictions" :user="user"/>
      </div>
    </div>
    <Toolbar v-if="isToolbarVisible"/>
    <span class="spinner" data-content="loading..."></span>
  </main>
</template>


<script>
import { useHead } from '@vueuse/head';
import SummaryTabGames from './tab_games';
import SummaryTabLanguages from './tab_languages';
import SummaryTabCurrencies from './tab_currencies';
import SummaryTabCertificates from './tab_certificates';
import SummaryTabRanks from './tab_ranks.vue'
import Toolbar from './toolbar';
import SummaryTabRestrictions from "@/pages/summary/tab_restrictions.vue";

export default {
    name: 'Summary',
    components: {
      SummaryTabRestrictions,
        SummaryTabGames,
        SummaryTabLanguages,
        SummaryTabCurrencies,
        SummaryTabCertificates,
        SummaryTabRanks,
        Toolbar
    },
    props: ['user'],
    data() {
        return {
            tab: 'games',
            marginTop: '0px',
            shown: {
                games: false,
                languages: false,
                certificates: false,
                currencies: false,
                restrictions: false
            }
        }
    },

    setup() {
        document.body.style.backgroundColor = '#fff';
        useHead({
            title: 'Summary - 3 Oaks Gaming'
        });
    },

    mounted () {
        this.tab = this.$route.query.tab || 'games';
        this.shown[this.tab] = true;
        this.calculateMarginTop();
        setTimeout(() => {
            this.calculateMarginTop();
        }, 0);
        window.addEventListener("resize", this.onResize);
    },

    destroyed () {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        isToolbarVisible() {
          return this.user.is_staff && (
            this.tab === 'currencies' || this.tab === 'restrictions'
          )
        }
    },

    methods: {
        onTabClick(e) {
            this.tab = e.currentTarget.getAttribute('href');
            this.shown[this.tab] = true;

            this.$router.replace({
                query: { tab: this.tab }
            })
            this.calculateMarginTop();
            setTimeout(() => {
                this.calculateMarginTop();
            }, 0);
        },

        onResize(e) {
            this.calculateMarginTop();
        },

        calculateMarginTop() {
            this.marginTop = this.$refs.header.clientHeight + 'px';
        },

    },
}
</script>
