<template>
  <footer class="footer">
    <div class="footer__content _container">
      <div class="footer__top">
        <div class="footer__info">
          <ul class="info-list">
            <li>
              <p><a href="/">3OAKS.COM</a> IS OWNED BY GREEN ROCK HOLDING LIMITED, A COMPANY
                INCORPORATED IN THE ISLE OF MAN AT THE REGISTERED ADDRESS:
                PART GROUND FLOOR SUITE, 60 CIRCULAR ROAD, DOUGLAS, ISLE OF MAN, IM1 1SA</p>
            </li>
            <li>
              <p>GREEN ROCK LIMITED AND ONE ROCK LIMITED ARE COLLECTIVELY MANAGING <a href="/">3OAKS.COM</a></p>
            </li>
            <li>
              <p>GREEN ROCK LIMITED , A COMPANY INCORPORATED IN THE ISLE OF MAN
                AT THE REGISTERED ADDRESS: PART GROUND FLOOR SUITE, 60 CIRCULAR ROAD, DOUGLAS,
                ISLE OF MAN, IM1 1SA. AND IS LICENCED AND REGULATED
                BY THE ISLE OF MAN GAMBLING SUPERVISION COMMISSION UNDER A SOFTWARE
                SUPPLIER LICENCE ISSUED UNDER THE ONLINE GAMBLING REGULATION ACT
                2001 ON 02/12/2021</p>
            </li>
            <li>
                <p>ONE ROCK LIMITED, A COMPANY INCORPORATED IN MALTA AT THE
                REGISTERED ADDRESS: NO 2, GERALDU FARRUGIA STREET, ZEBBUG ZBG
                4351, MALTA</p>
            </li>
            <li>
                <p>UNDER THE ISLE OF MAN AND MALTA LAWS THE CASINO GAMES AND
                ACTIVITIES ARE PROHIBITED TO THOSE UNDER THE AGE OF 18</p>
            </li>
            <li>
                <div class="license-info">
                    <div>
                        <p>LICENSED BY</p>
                        <div>
                            <div class="footer-img">
                                <a
                                href="https://www.gov.im/categories/business-and-industries/gambling-and-e-gaming/licence-holders/"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                >
                                    <img src="@/assets/certificates/img/IoM_logo.png" alt="3oaks">
                                </a>
                            </div>
                            <div class="footer-img">
                                <a
                                    href="https://registers.gamblingcommission.gov.uk/63055"
                                    target="_blank"
                                    rel="nofollow noopener noreferrer"
                                >
                                    <img src="@/assets/certificates/img/gambling-commission-logo.png" alt="3oaks">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>ACCREDITED BY</p>
                        <div>
                            <div class="footer-img">
                                <a href="/static/media/GNR_IOM_220124_RC_R1_3_Oaks_Gaming_RNG_Evaluation_Report_for_IoM.pdf"
                                target="_blank"
                                >
                                    <img src="@/assets/certificates/img/GA_RNG_certified_logo.png"
                                        alt="3oaks">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <p style="padding-top: 2em;">
                  Green Rock Limited is licensed and regulated in Great Britain by the Gambling Commission under account number
                  <a href="https://registers.gamblingcommission.gov.uk/63055" rel="nofollow noopener noreferrer">63055</a>.
                </p>
            </li>
            <li>
            </li>
          </ul>
        </div>

        <div class="footer__navigation">
          <div class="footer-menu">
            <ul class="footer-menu__list">
              <li v-for="menuItem of menuList.slice(
                  0, Math.round(menuList.length/2))"
              >
                <span class="split-link">
                  <a class="normal" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                  <a class="normal-hovered _hover" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                </span>
              </li>
            </ul>
            <ul class="footer-menu__list">
              <li v-for="menuItem of menuList.slice(
                  Math.round(menuList.length/2), menuList.length)"
              >
                <span class="split-link">
                  <a class="normal" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                  <a class="normal-hovered _hover" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                </span>
              </li>
            </ul>
          </div>

          <div class="footer-links">
            <p data-da=".footer-links,992,0">Networks</p>
            <div class="footer-links__list">
              <SocialLinks />
            </div>
          </div>

          <div class="footer-text">
            <div>
              <p>ABOUT 3 OAKS</p>
              <p>3 Oaks Gaming is a fast-growing distributor of
                iGaming content who works alongside established
                high-value studios to distribute casino content
                and marketing tools to regulated markets across
                the globe</p>
            </div>
            <div>
              <p>ALL RIGHTS RESERVED</p>
              <p>3 Oaks` logos and graphic materials are the company's
                intellectual property and may not be copied, reproduced,
                distributed or displayed without the written consent
                of Green Rock LTD</p>
            </div>
          </div>

        </div>
      </div>

      <div class="footer__bottom">
        <div>
            <p>18+ RESPONSIBLE GAMING</p>
            <p><a href="https://www.gambleaware.org/" target="_blank">gambleaware.org</a></p>
          <!--
          <p>
            For business inquiries:
            <a v-bind:href="`mailto:${salesEmail}`">{{ salesEmail }}</a>
          </p>
          -->
        </div>
        <div>
          <p><a href="/privacy-notice" target="_blank">Privacy Policy</a></p>
          <p>© {{ year }} 3 Oaks Gaming. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
import SocialLinks from './social_links'

export default {
    name: "Footer",
    props: {
        menuList: Array
    },

    data() {
        return {
            salesEmail: process.env.VUE_APP_SALES_EMAIL,
            year: new Date().getFullYear()
        }
    },

    components: {
        SocialLinks
    }
}
</script>
