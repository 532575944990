<template xmlns="http://www.w3.org/1999/html">
  <div class="news-tabs" ref="root">
    <div class="tabs-block _scr-item _tabs">
      <h6 class="offset-title" v-if="titleHtml" v-html="titleHtml"></h6>

      <div class="_tabs-mobile" v-if="user.is_staff">
        <div
            class="tabs-mobile__value _icon-arrow-1"
            ref="categories-select-box"
            @click.prevent="onSelectBoxClick">
          {{ this.currentCategory.title }}
        </div>
        <nav
            v-if="categories.length > 1"
            class="tabs-block__nav tabs-mobile__options"
            :class="{active: isCategoriesContainerActive}"
            ref="categories-container">
          <router-link
              v-for="category of categories"
              :to="{name: this.$route.name.toString(), query: {category: category.slug}}"
              class="tabs-block__item _tabs-item"
              :class="{_active: this.currentCategory === category}"
          >
            {{ category.title }}
          </router-link>
        </nav>
      </div>

      <div class="tabs-block__body">
        <div class="tabs-block__block _tabs-block _active" v-if="articles.length">
          <div class="news-grid__container">
            <NewsCard
                v-for="item of articles"
                :title="item.title"
                :image-url="item.image"
                :category="item['category_title']"
                :publish-date="item['c_at']"
                :article-url="item.path"
                :is-published="item['is_published']"
                :class="{loading : loading}"
                :preload="true"
            />
          </div>

        </div>

        <div class="tabs-block__block _tabs-block _active" v-if="!articles.length && !loading">
          There is no news in the current category
        </div>
      </div>
    </div>
    <div>
      <Paging
          v-if="showPagination"
          :total-pages="totalPages"
          :current-page="currentPage"
          @on-page-click="onPageClick"
      />
    </div>
  </div>

</template>

<script>
import {appFetchJSON, processFetchError} from "@/lib/request_utils";
import {scrollToElement, loadImage} from "@/lib/common_utils";
import EventBus from "@/lib/event_bus";
import NewsCard from "@/components/news_card";
import Paging from "@/components/paging";

export default {
    name: "NewsList",
    components: {
        NewsCard,
        Paging
    },
    props: {
        user: Object,
        titleHtml: String,
        showPagination: Boolean,
        itemPerPageEven: {
            type: Number,
            default: 10
        },
        itemPerPageOdd: {
            type: Number,
            default: 9
        },
    },
    data() {
        return {

            articles: [],
            categories: [],
            currentCategory: {},
            defaultCategory: {
                title: 'All',
                position: -1,
                slug: 'all',
            },
            categoryBySlug: {},
            currentPage: 1,
            totalPages: 1,
            isCategoriesContainerActive: false,
            loading: true,
        }
    },

    mounted() {
        this.fetchNewsCategories().then(() => this.updateData());
    },

    watch: {
        '$route.query'() {
            this.isCategoriesContainerActive = false;
            this.updateData();
        }
    },

    methods: {
        updateData() {
            this.updateCurrentCategory();
            this.updateCurrentPage();
            this.loading = true;
            return this.fetchNews().then(() => this.loading = false);
        },

        fetchNewsCategories() {
            let url = '/news/categories';
            return appFetchJSON(url)
                .then(res => {
                    let categories = res.data.items || [];
                    categories.push(this.defaultCategory);
                    categories.sort((a, b) => {
                        if (a.position > b.position) return 1;
                        if (a.position < b.position) return -1;
                        if (a.title > b.title) return 1;
                        if (a.title < b.title) return -1;
                        return 0;
                    });
                    this.categoryBySlug = {};
                    categories.forEach((value) => {
                        let routeData = {name: this.$route.name.toString(), query: {category: value.slug}};
                        let route = this.$router.resolve(routeData);
                        value.path = route.path;
                        this.categoryBySlug[value.slug] = value;
                    });
                    this.categories = categories;

                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        fetchNews() {
            let url = '/news/articles';
            let options = {params: {}};
            let currentCategoryUID = this.currentCategory.uid;
            if (currentCategoryUID) {
                options.params.category = currentCategoryUID;
            }
            options.params['page_num'] = this.currentPage;

            let pageItemsNum = this.itemPerPageEven;
            if (window.innerWidth >= 1144) {
                pageItemsNum = this.itemPerPageOdd;
            }
            options.params['page_items_num'] = pageItemsNum;

            return appFetchJSON(url, options)
                .then(res => {
                    let articles = res.data.items || [];
                    articles.forEach((value) => {
                        let routeData = {name: 'news-article', params: {article: value.slug}};
                        let route = this.$router.resolve(routeData);
                        value.path = route.path;
                    });
                    articles.sort((a, b) => (a.c_at > b.c_at) ? -1 : 1);

                    this.articles = articles;
                    this.totalPages = res.data['total_pages'];
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        updateCurrentCategory() {
            let categorySlug = this.$route.query?.category || this.defaultCategory.slug;
            this.currentCategory = this.categoryBySlug[categorySlug] || this.defaultCategory;
        },

        updateCurrentPage() {
            if (this.showPagination) {
                this.currentPage = Number(this.$route.query?.page) || 1;
            }
        },

        onPageClick(pageNum) {
            if (pageNum === 1) {
                pageNum = undefined;
            }
            let routeData = {
                name: this.$route.name.toString(),
                query: {...this.$route.query || {}, page: pageNum}
            };
            this.$router.replace(routeData);
            this.$nextTick(() => {
                scrollToElement(this.$refs.root, 0).then(() => {
                    EventBus.emit(EventBus.EVENTS.HEADER_HIDE);
                });
            });
        },

        onSelectBoxClick() {
            this.isCategoriesContainerActive = !this.isCategoriesContainerActive;
        },
    }
}
</script>

<style scoped>

</style>