<template>
    <div class="nds-common__container-y" v-if="isVisible">
        <h2 class="nds-common__title">
            <span>Prizes</span>
        </h2>
        <div class="nds-common__container-x nds-tables">
          <div class="nds-tables__wrapper nds-prizes">
              <table class="">
                <thead>
                  <tr>
                    <th class="nds-cell-prizes-prize-drop-prize">
                        Amount
                    </th>
                    <th class="nds-cell-prizes-prize-drop-prize-count">
                        Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in prizesToDisplay"
                    :class="{'top3': item.index <= 3, 'top10': item.index >= 4}">

                      <td class="nds-cell-prizes-prize-drop-prize">
                          <PrizeDropPrizesComponent
                              :prize="item.prize"
                              :date-locale="dateLocale"
                              :date-time-zone="dateTimeZone"
                          />
                          <span v-if="item.prize.bet_multiplier"
                                class="nds-cell-prizes-prize-drop-multiplier">
                              bet multiplier
                          </span>
                      </td>

                      <td class="nds-cell-prizes-prize-drop-prize-count">
                          <div class="nds-prize-drop-prize-wrapper">
                            <div class="nds-prize-drop-prize">
                                  <div>Count: {{ item.total_count }}</div>
                                  <div>Wins: {{ item.win_count }}</div>
                                  <div>Left: {{ item.left_count }}</div>
                                  <div class="nds-prize-progress-bar-wrapper">
                                      <div class="nds-prize-progress-bar" :style="{ width: progressPercentage(item) + '%' }"></div>
                                  </div>
                              </div>
                        </div>
                      </td>
                  </tr>
                </tbody>
              </table>
          </div>
      </div>
    </div>
</template>

<script>
import PrizeDropPrizesComponent from "@/pages/tournament/components/prize_drop_prizes_component.vue";

export default {
    name: "PrizeDropPrizesListSection",
    components: {
        PrizeDropPrizesComponent,
    },
    props: {
        prizes: {
            type: Object,
            default: [],
        },
        currency: {
            type: String,
            default: '',
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },
    data() {
        return {
            prizesToDisplay: []
        }
    },
    methods: {
        preparePrizes(items, currency) {
            return items.map((item, index) => {
                item.index = index+1
                item.place = index+1
                item.progress = this.progressPercentage(item)

                // Mock: is needed temporarily for debugging
                // if (index === 0) {
                //     item.left_count = item.total_count - 210
                //     item.win_count = 210
                // }
                // if (index === 2) {
                //     item.left_count = item.total_count - 45
                //     item.win_count = 45
                // }
                // if (index === 3) {
                //     item.left_count = item.total_count - 346
                //     item.win_count = 346
                // }
                // if (index === 4) {
                //     item.left_count = item.total_count - 127
                //     item.win_count = 127
                // }
                return item
            })
        },
        progressPercentage(item) {
            return ((item.win_count / item.total_count) * 100).toFixed(2)
        }
    },
    mounted() {
        this.prizesToDisplay = this.preparePrizes(this.prizes)
    },
    computed: {
        isVisible() {
            const prizesToDisplay = this.prizesToDisplay
            return prizesToDisplay && prizesToDisplay.length > 0;
        },
    }
}
</script>

<style scoped>

</style>