<template>
  <div class="game-card">
    <a v-if="item.has_client_page" class="game-card__body" :href="itemURL" target="_blank">
      <div class="game-card__image _ibg">
        <img :src="item.logo" alt="3oaks">
      </div>
      <div class="game-card__description _icon-arrow-3">
        <p>{{ item.title_en }}</p>
      </div>
    </a>
    <div v-else class="game-card__body">
      <div class="game-card__image _ibg">
        <img :src="item.logo" alt="3oaks">
      </div>
      <div class="game-card__description">
        <p>{{ item.title_en }}</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "GamesSliderCard",
  props: {
    item: {
      type: Object,
      default: {
        title_en: '',
        logo: ''
      }
    }
  },

  computed: {
    itemURL() {
      const routeParams = { name: 'client-area-game', params: { name: this.item.slug } };
      return this.$router.resolve(routeParams).fullPath;
    }
  }
}
</script>