<template>
  <div class="games-grid__controls">

    <form action="#" class="search-form">
<!--        @submit.prevent="onSearchUpdate"-->
      <div class="search-form__grid">

        <!-- will be implemented -->
        <!-- <SearchFormSelect
            v-for="filterName in selectFilersNamesList"
            :options="filtersOptions[filterName]"
            v-model="filters[filterName]"
            @update:model-value="(value) => onFilterUpdate(filterName, value)"
        /> -->

        <SearchFormSort
            :options="sortOptions"
            v-model="sortModel"
            @update:model-value="(value) => onSortUpdate(value)"
        />

        <div class="search-form__grid-item">
            <div class="filter-form__checks">
                <div class="checkbox">
                    <input
                        type="checkbox"
                        class="checkbox__input _req"
                        id="input-hide-details"
                        v-model="isDetailsHidden"
                    />
                    <label for="input-hide-details" class="checkbox__label">
                    <span>Hide details</span>
                    </label>
                </div>
            </div>
        </div>

        <div class="search-form__grid-item">
          <div class="search">
            <input
                autocomplete="off"
                type="text"
                name="form[]"
                placeholder="Search by a game’s name"
                class="search-form__input"
                @input="onSearchUpdate"
                v-model="searchInputValue"
                @keydown.enter.prevent="onSearchUpdate"
            />
              <span class="search-form__btn"></span>
          </div>
        </div>

      </div>
    </form>

  </div>
  <div ref="gameGridContainer"></div>
  <div class="games-grid__container" v-if="items.length > 0" ref="scrollContainer">
    <GamesGridCard
        v-for="item of items"
        :image="item.image"
        :guide-url="item.guide_url"
        :promo-pack-url="item.promo_pack_url"
        :release-date="item.release_date"
        :text="item.title_text"
        :has-page="item.has_page"
        :page-url="item.page_url"
        :is-loading="item.is_loading"
        :is-details-hidden="isDetailsHidden"
    />
  </div>
  <div></div>
  <div v-if="items.length === 0 && !isGameListLoading">
    There are no games found for your search
  </div>
</template>


<script>
import {loadImages} from "@/lib/common_utils";
import {appFetchJSON, processFetchError} from '@/lib/request_utils';
import GamesGridCard from './games_grid_card'
import SearchFormSelect from "@/components/forms/search-form-select";
import SearchFormSort from "@/components/forms/search-form-sort";

const DOCUMENT_TYPE_PROMO_PACK = 'game_promo';
const DOCUMENT_TYPE_GUIDE = 'game_guide';

const FILTER_OPTION_ALL_MAPPING = {
    'game_category': 'All Games',
    'jackpot_type': 'All Jackpot Types'
}
const FILTER_TITLE_NOT_SET = 'Not Set';

const SORT_OPTIONS_MAPPING = {
    'name_asc': {
        title: 'a to z',
        field: 'name',
        direction: 'asc'
    },
    'name_desc': {
        title: 'z to a',
        field: 'name',
        direction: 'desc'
    },
    // 'most_popular_desc': {
    //     title: 'Most popular',
    //     field: 'most_popular',
    //     direction: 'desc'
    // },
    'release_date_desc': {
        title: 'release date ↑',
        field: 'release_date',
        direction: 'desc'
    },
    'release_date_asc': {
        title: 'release date ↓',
        field: 'release_date',
        direction: 'asc'
    },

}
const SORT_OPTIONS_DEFAULT = 'release_date_desc';


export default {
    name: "GamesGrid",
    components: {
        GamesGridCard,
        SearchFormSelect,
        SearchFormSort,
    },

    props: {
        user: Object,
    },

    data() {
        return {
            items: [],
            currentPage: 1,
            totalPages: 0,
            filters: {},
            filtersOptions: {},
            sortOptions: [],
            sortModel: '',
            searchInputValue: '',
            isGameListLoading: false,
            isDetailsHidden: false,
            isNextChunkLoaded: false,
            isScrollEventHandled: false
        }
    },

    mounted() {
        this.debounceTimer = null;
        this.isGameListLoading = true;
        this.fillSortOptions();
        this.getFiltersOptions().then(() => {
            this.updateDataFromRouteQuery();
            this.getGames().then(() => {
                this.isGameListLoading = false
            });
        });
        window.addEventListener("scroll", this.handleScroll);
    },

    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    watch: {
        '$route.query'() {
            this.currentPage = 1;
            this.updateDataFromRouteQuery();
            this.getGames().then(() => {
                this.isGameListLoading = false;
            });
        },
    },

    computed: {
        selectFilersNamesList() {
            return Object.keys(this.filtersOptions);
        }
    },

    methods: {
        getFiltersOptions() {
            if (!this.user['is_authorized']) {
                return Promise.resolve();
            }

            return appFetchJSON(`/games/filters`)
                .then(res => {
                    const data = res.data || {};
                    for (const [filterName, filterOptionsData] of Object.entries(data)) {
                        if (!filterOptionsData || filterOptionsData.length === 0) {
                            continue;
                        }
                        const filterOptions = [];
                        filterOptionsData.forEach((item) => {
                            let title;
                            if (item) {
                                title = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
                            } else {
                                title = FILTER_TITLE_NOT_SET;
                            }
                            filterOptions.push({
                                'title': title,
                                'value': item,
                            })
                        })
                        filterOptions.sort((a, b) => {
                            if (a.title === FILTER_TITLE_NOT_SET) return -1;
                            if (b.title === FILTER_TITLE_NOT_SET) return 1;
                            if (a.title === b.title && a.title === FILTER_TITLE_NOT_SET) return 0;
                            if (a.title < b.title) return -1;
                            if (a.title > b.title) return 1;
                            return 0;
                        });
                        filterOptions.unshift(
                            {
                                title: FILTER_OPTION_ALL_MAPPING[filterName] || 'All',
                                value: null,
                            }
                        );
                        this.filtersOptions[filterName] = filterOptions;
                        this.filters[filterName] = filterOptions[0].value;
                    }
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        fillSortOptions() {
            this.sortOptions.length = 0;
            for (const [sortrName, sortOptionsData] of Object.entries(SORT_OPTIONS_MAPPING)) {
                const sortOption = {
                    title: sortOptionsData.title,
                    value: sortrName,
                }
                this.sortOptions.push(sortOption);
            }
        },

        getGames() {
            const pageItemsNum = 15;

            const currentStartIndex = this.items.length

            for (let i = 0; i < pageItemsNum; i++) {
                this.items.push({
                    'is_loading': true,
                });
            }

            const params = {
                'page_num': this.currentPage,
                'page_items_num': pageItemsNum,
                'game_name': this.searchInputValue,
            };

            for (const [filterName, filterValue] of Object.entries(this.filters)) {
                if (filterValue !== null && filterValue !== undefined) {
                    params[filterName] = filterValue;
                }
            }

            const sortItem = SORT_OPTIONS_MAPPING?.[this.sortModel];

            if (sortItem) {
                params['sort'] = `${sortItem.field}:${sortItem.direction}`;
            }

            let gamesApiUrl = '/games';
            if (this.user['is_authorized']) {
                gamesApiUrl = '/games/client_area'
            }

            return appFetchJSON(gamesApiUrl, {params: params})
                .then(res => {
                    const items = res.data?.items || [];
                    this.totalPages = res.data['total_pages'];

                    if (items.length === 0) {
                        this.items.length -= pageItemsNum;
                        return;
                    }
                    const imagesToLoad = [];
                    items.forEach((item) => {
                        if (item['has_page']) {
                            const routeName = this.$route.name;
                            const targetRouteName = routeName === 'client-area-games' ? 'client-area-game' : 'game';
                            const routeParams = {name: targetRouteName, params: {name: item.name}};
                            item['page_url'] = this.$router.resolve(routeParams).fullPath;
                        }
                        item['guide_url'] = item.documents?.[DOCUMENT_TYPE_GUIDE];
                        item['promo_pack_url'] = item.documents?.[DOCUMENT_TYPE_PROMO_PACK];

                        let itemImageSrc = item['main_logo_file'];
                        if (!itemImageSrc) {
                            itemImageSrc = require('@/assets/img/games/game-image-placeholder.svg');
                        }
                        const itemImage = new Image();
                        item['image'] = itemImage;
                        imagesToLoad.push({imgObj: itemImage, imgSrc: itemImageSrc});
                    });

                    return loadImages(imagesToLoad).then(() => {
                        for (let i = 0; i < items.length; i++) {
                            const currentItem = this.items[currentStartIndex + i];
                            currentItem['is_loading'] = false;
                            this.items[currentStartIndex + i] = {...currentItem, ...items[i]};
                        }
                        this.items.splice(
                            currentStartIndex + items.length,
                            pageItemsNum - items.length
                        );
                    });
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        updateDataFromRouteQuery() {

            const routeQuery = this.$route.query || {};

            this.searchInputValue = routeQuery['game_name'] || '';

            const filters = this.filters;
            this.selectFilersNamesList.forEach((filterName) => {
                let filterOptions = this.filtersOptions[filterName];
                if (filterOptions) {
                    let routeQueryValue = routeQuery[filterName];
                    if (routeQueryValue !== undefined) {
                        let filterOptions = this.filtersOptions[filterName] || [];
                        filterOptions.forEach((item) => {
                            if (item.value === routeQueryValue) {
                                filters[filterName] = item.value;
                            }
                        });
                    }
                }
            });

            const sortQuery = routeQuery?.['sort'];
            if (sortQuery) {
                const sortItemKey = sortQuery.replace(':', '_');
                if (SORT_OPTIONS_MAPPING.hasOwnProperty(sortItemKey)) {
                    this.sortModel = sortItemKey;
                }
            }
            if (!this.sortModel) {
                this.sortModel = SORT_OPTIONS_DEFAULT;
            }
        },

        loadItems() {
            this.isNextChunkLoaded = true;
            if (this.currentPage + 1 > this.totalPages) {
                this.isNextChunkLoaded = false;
                return;
            }
            this.currentPage++;
            return this.getGames().then(() => {
                this.isNextChunkLoaded = false;
            })
        },

        applyFilters(resetPage) {
            const filtersQuery = {};

            const searchInputValue = this.searchInputValue;
            if (searchInputValue) {
                filtersQuery['game_name'] = searchInputValue;
            }

            for (const [filterName, filterValue] of Object.entries(this.filters)) {
                if (filterValue !== null && filterValue !== undefined) {
                    filtersQuery[filterName] = filterValue;
                }
            }

            const sortItem = SORT_OPTIONS_MAPPING?.[this.sortModel];
            if (sortItem) {
                filtersQuery['sort'] = `${sortItem.field}:${sortItem.direction}`;
            }

            if (resetPage) {
                this.items.length = 0;
            }

            return this.reload(filtersQuery);
        },

        reload(query) {
            const routeData = {
                name: this.$route.name,
                query: query,
            };
            return this.$router.replace(routeData).then(() => {
                this.items = []
                this.isGameListLoading = true
                this.getGames()
            });
        },

        // will be implemented
        // onFilterUpdate(name, value) {
        //     this.applyFilters(true);
        // },

        onSortUpdate(value) {
            this.applyFilters(true);
        },

        onSearchUpdate() {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                if (this.searchInputValue.length >= 2 || this.searchInputValue.length === 0) {
                    this.isGameListLoading = true;
                    this.applyFilters(true);
                }
            }, 500)
        },

        handleScroll(e) {
            if (this.isNextChunkLoaded) {
                return;
            }

            const el = this.$refs.scrollContainer;
            if (!el) {
                return;
            }

            if (el.getBoundingClientRect().bottom + 100 < window.innerHeight) {
                this.loadItems();
            }

        }
    },
}
</script>
