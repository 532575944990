<template>
  <div class="campaigns-grid">
    <div class="campaigns-grid-item" v-for="item of items">

      <div class="campaigns-grid-item-banner" :style="{ backgroundImage: getItemStyle(item) }">
        <a :href="item['url']"><div></div></a>
      </div>

      <div class="campaigns-grid-item-data">
        <div class="campaigns-grid-item-data-name">
          <div>
            <div>{{ item["name"] }}</div>
            <div class="splitter"></div>
          </div>
          <div class="status" :class="{ 'active': item['is_active'] }">
            {{ getCampaignStatus(item) }}
          </div>
        </div>
        <div class="campaigns-grid-item-data-description">
          <span>{{ item["short_description"] }}</span>
          <div class="campaigns-grid-item-data-page-url campaigns-grid-item-data-description-page-url">
            <a :href="item['url']">
              <div>show more</div>
            </a>
          </div>
        </div>
        <div class="campaigns-grid-item-data-date">
          FROM
          <span>
            {{ getDate(item["start_date"]) }}
          </span>
          TO
          <span>
            {{ getDate(item["end_date"]) }}
          </span>
          <div class="campaigns-grid-item-data-page-url campaigns-grid-item-data-date-page-url">
            <a :href="item['url']">
              <div>show more</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateWidget from "@/components/date_widget";

export default {
  name: "CampaignGrid",

  components: {
    DateWidget,
  },

  props: {
    items: Array,
  },

  methods: {
    getItemStyle(item) {
      const bannerURL = item["banner_list_page"];
      if (bannerURL) {
        const style = `url('${bannerURL}')`;
        return style;
      }
      return "";
    },

    getDate(isoDateString) {
      const date = new Date(isoDateString);
      const [day, month, year] = [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
      ].map((item) => {
        return String(item).padStart(2, "0");
      });
      return `${day} | ${month} | ${year}`;
    },

    getCampaignStatus(item) {
      if (item['is_active']) return 'ACTIVE'
      if (item['is_upcoming']) return 'UPCOMING'
      return 'ENDED'
    }
  },
};
</script>
