<template xmlns:MoveLine="http://www.w3.org/1999/xlink">
  <main class="page campaigns-user campaigns-user-page">
    <div class="page__body campaigns-user__body">
      <div class="section-banner">
        <div class="section-banner-bg" :style="{ backgroundImage: `url('${bannerBGURL}')` }"></div>
        <div class="section-banner-bg-mobile" :style="{ backgroundImage: `url('${bannerBGMobileURL}')` }"></div>
        <div class="section-banner-content">
          <div class="section-banner-content-logo" :style="{ backgroundImage: `url('${bannerLogoURL}')` }"></div>
          <div class="section-banner-content-prize-pool" :style="{ backgroundImage: `url('${bannerPrizePoolURL}')` }">
          </div>
          <div class="section-banner-content-prize-pool-mobile"
            :style="{ backgroundImage: `url('${bannerPrizePoolMobileURL}')` }"></div>
          <div class="section-banner-content-breadcrumbs _container">
            <BreadCrumbs :links="breadCrumbsLinks" />
          </div>
          <div class="section-banner-content-date-plate">
            <span>FROM</span>
            <span class="section-banner-content-date-plate-date">
              {{ startDate }}
            </span>
            <span>TO</span>
            <span class="section-banner-content-date-plate-date">
              {{ endDate }}
            </span>
          </div>
          <div class="section-banner-content-arrow" @click.prevent="onArrowClick"></div>
        </div>
      </div>

      <div class="_container section section-campaign-name">
        <div class="campaign-name">
          {{ campaign['name'] }}
        </div>
        <div class="campaign-documents">
          <DocumentCard v-for="item of campaign['documents']" :title="item['title']" :url="item['url']" target="_blank"
            :show-card-title="true" />
        </div>
      </div>

      <div class="section-splitter"></div>

      <div class="_container section section-description">
        <div class="section-title">DESCRIPTION</div>
        <div class="section-description-description" v-html="campaign['description']"></div>
      </div>

      <div class="section-splitter"></div>

      <div class="_container section section-series-settings">
        <div class="section-title">Series Settings for Each Month</div>
        <div v-html="campaign['series_settings']"></div>
      </div>

      <div class="section-splitter"></div>

      <div class="_container section section-structure">
        <div class="section-title">STRUCTURE</div>
        <div class="structure-container">
          <div class="structure-item" v-for="item of campaign['structures'] || []"
            :class="{ 'active': item['is_active'], 'expanded': structureItemsState[item['uid']]['expanded'] }">
            <div class="structure-item-plate" @click.prevent="onStructureItemClick(item)">
              <div class="structure-item-plate-data">
                <div class="structure-item-plate-data-acorn"></div>
                <div class="structure-item-plate-data-info">
                  <div>{{ getStructureItemName(item) }}</div>
                  <div>{{ item['description'] || '' }}</div>
                </div>
                <div class="structure-item-plate-data-status">
                  {{ getStructureItemStatus(item) }}
                </div>
              </div>
              <div class="structure-item-plate-expand-arrow"></div>
            </div>
            <div class="structure-item-content">
              <div class="structure-item-content-item" v-if="item['promo_materials_url']">
                <div class="structure-item-content-item-header">
                  <div class="structure-item-content-item-header-arrow"
                    :class="{ 'expanded': structureItemsState[item['uid']].sections.promoMaterialsURL }"
                    @click.prevent="onStructureSectionItemClick(item, { uid: 'promoMaterialsURL' })"></div>
                  <div class="structure-item-content-item-header-name"
                    @click.prevent="onStructureSectionItemClick(item, { uid: 'promoMaterialsURL' })">Event promo
                    materials</div>
                  <div class="structure-item-content-item-header-action-area"></div>
                </div>
                <div class="structure-item-content-item-content"
                  :class="{ 'expanded': structureItemsState[item['uid']].sections.promoMaterialsURL }">
                  <DocumentCard :url="item['promo_materials_url']" title="Promo Materials" :show-card-title="true"
                    target="_blank" />
                </div>
              </div>

              <div class="structure-item-content-item" v-if="item['holiday_promo_materials_url']">
                <div class="structure-item-content-item-header">
                  <div class="structure-item-content-item-header-arrow"
                    :class="{ 'expanded': structureItemsState[item['uid']].sections.holidayPromoMaterialsURL }"
                    @click.prevent="onStructureSectionItemClick(item, { uid: 'holidayPromoMaterialsURL' })"></div>
                  <div class="structure-item-content-item-header-name"
                    @click.prevent="onStructureSectionItemClick(item, { uid: 'holidayPromoMaterialsURL' })">Holiday
                    promo
                    materials</div>
                  <div class="structure-item-content-item-header-action-area"></div>
                </div>
                <div class="structure-item-content-item-content"
                  :class="{ 'expanded': structureItemsState[item['uid']].sections.holidayPromoMaterialsURL }">
                  <DocumentCard :url="item['holiday_promo_materials_url']" title="Holiday Promo Materials"
                    :show-card-title="true" target="_blank" />
                </div>
              </div>

              <div class="structure-item-content-item" v-if="(item['games'] || []).length > 0">
                <div class="structure-item-content-item-header">
                  <div class="structure-item-content-item-header-arrow"
                    :class="{ 'expanded': structureItemsState[item['uid']].sections.games }"
                    @click.prevent="onStructureSectionItemClick(item, { uid: 'games' })"></div>
                  <div class="structure-item-content-item-header-name"
                    @click.prevent="onStructureSectionItemClick(item, { uid: 'games' })">Games</div>
                  <div class="structure-item-content-item-header-action-area">
                    <div class="copy-games-list-badge"
                      :class="{ 'visible': structureItemsState[item['uid']].sections.games }"
                      @click.prevent="onCopyGamesListClick(item)"></div>
                  </div>
                </div>
                <div class="structure-item-content-item-content"
                  :class="{ 'expanded': structureItemsState[item['uid']].sections.games }">
                  <GamesSlider :games="item['games']" :sliderCardComponent="GamesSliderCard" />
                </div>
              </div>

              <div class="structure-item-content-item" v-for="customSectionItem of item['custom_sections']">
                <div class="structure-item-content-item-header">
                  <div class="structure-item-content-item-header-arrow"
                    :class="{ 'expanded': structureItemsState[item['uid']].sections[customSectionItem['uid']] }"
                    @click.prevent="onStructureSectionItemClick(item, customSectionItem)"></div>
                  <div class="structure-item-content-item-header-name"
                    @click.prevent="onStructureSectionItemClick(item, customSectionItem)">
                    {{ customSectionItem['name'] }}
                  </div>
                  <div class="structure-item-content-item-header-action-area"></div>
                </div>
                <div class="structure-item-content-item-content"
                  :class="{ 'expanded': structureItemsState[item['uid']].sections[customSectionItem['uid']] }">
                  {{ customSectionItem['description'] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import { markRaw } from "vue";

import BreadCrumbs from "@/components/breadcrumbs";
import DocumentCard from '@/components/document_card';
import DocumentsSlider from '@/components/documents_slider';
import GamesSlider from '@/pages/campaign/games_slider';
import GamesSliderCard from '@/pages/campaign/games_slider_card';
import MoveLine from "@/components/move_line";
import Swiper from "@/assets/js/Swiper";
import { appFetchJSON } from "@/lib/request_utils";
import DateWidget from "@/components/date_widget";

export default {
  name: "Campaign",
  components: {
    BreadCrumbs,
    DateWidget,
    DocumentCard,
    DocumentsSlider,
    GamesSlider,
    GamesSliderCard,
    MoveLine
  },
  data() {
    return {
      breadCrumbsLinks: [{ title: 'Campaigns', href: '/client-area/campaigns' }],
      campaign: {},
      structureItemsState: {},
      dateOptions: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC',
        timeZoneName: 'short'
      },
      GamesSliderCard: markRaw(GamesSliderCard),
    }
  },

  mounted() {
    this.fetchCampaign().then(() => {
      this.updateBreadCrumbs();
    });
  },

  computed: {
    bannerBGURL() {
      return this.campaign['banner_bg'] || ""
    },

    bannerBGMobileURL() {
      return this.campaign['banner_bg_mobile'] || ""
    },

    bannerLogoURL() {
      return this.campaign['banner_logo'] || ""
    },

    bannerPrizePoolURL() {
      return this.campaign['banner_prize_pool'] || ""
    },

    bannerPrizePoolMobileURL() {
      return this.campaign['banner_prize_pool_mobile'] || ""
    },

    startDate() {
      return this.getDate(this.campaign["start_date"])
    },

    endDate() {
      return this.getDate(this.campaign["end_date"])
    },
  },

  methods: {
    fetchCampaign() {
      let url = `/campaigns/${this.$route.params.name}`;
      return appFetchJSON(url, {})
        .then((res) => {
          const campaign = res.data.item;
          const documents = campaign.documents || [];
          documents.forEach((doc) => {
            if (doc.type === 'campaign_tc') doc.title = 'Terms & Conditions';
            else if (doc.type === 'campaign_promo') doc.title = 'Promo Materials'
          });
          documents.sort((a, b) => {
            if (a.type === 'campaign_tc') return 1;
            if (b.type === 'campaign_tc') return -1;
            if (a.type === b.type && a.type === 'campaign_tc') return 0;
            if (a.title > b.title) return -1;
            if (a.title < b.title) return 1;
            return 0;
          });

          const structureItemsState = {}
          const structures = campaign['structures'] || [];
          structures.forEach((item) => {

            const games = item['games'] || [];
            games.forEach((gameItem) => {
              const routeParams = { name: 'client-area-game', params: { name: gameItem['slug'] } };
              gameItem.url = this.$router.resolve(routeParams).fullPath;
              gameItem.title = gameItem['title_en'];
            });

            const stateData = {
              expanded: false,
              sections: {
                promoMaterialsURL: false,
                holidayPromoMaterialsURL: false,
                games: false,
              }
            }
            const customSections = item['custom_sections'] || []
            customSections.forEach((sectionItem) => {
              stateData.sections[sectionItem['uid']] = false
            })
            structureItemsState[item['uid']] = stateData;
          });
          this.structureItemsState = structureItemsState;
          this.campaign = campaign;
        })
        .catch((err) => {
          const resp = err.response;
          if (resp && resp.status === 404) {
            this.$router.push({ 'name': 'page-not-found' });
          } else {
            throw err;
          }
        });
    },

    getDate(isoDateString) {
      const date = new Date(isoDateString);
      const [day, month, year] = [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
      ].map((item) => {
        return String(item).padStart(2, "0");
      });
      return `${day} | ${month} | ${year}`;
    },

    getStructureItemStatus(item) {
      if (item['is_active']) return 'ACTIVE'
      if (item['is_upcoming']) return 'UPCOMING'
      return 'ENDED'
    },

    getStructureItemName(item) {
      if (item['name']) return item['name'];

      const getFormatedDate = (date) => {
        const formatOptions = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
          timeZoneName: "short",
        }
        const dateTimeFormat = new Intl.DateTimeFormat("en-US", formatOptions);
        const fullDateParts = dateTimeFormat.formatToParts(date);
        const dateData = {};
        fullDateParts.forEach((item) => {
          dateData[item.type] = item.value;
        })

        const fullDate = `${dateData.day} ${dateData.month} ${dateData.year} at ${dateData.hour}:${dateData.minute} ${dateData.timeZoneName}`
        return fullDate

      }

      const startDate = getFormatedDate(new Date(item['start_date']));
      const endDate = getFormatedDate(new Date(item['end_date']));

      return `${startDate} - ${endDate}`;
    },

    updateBreadCrumbs() {
      this.breadCrumbsLinks = [
        { title: 'Campaigns', href: '/client-area/campaigns' },
        { title: this.campaign.name }
      ];
    },

    onStructureItemClick(item) {
      const sis = this.structureItemsState[item.uid]
      const is_expanded = !sis['expanded'];
      sis['expanded'] = is_expanded
      if (!is_expanded) {
        for (const key of Object.keys(sis.sections)) {
          sis.sections[key] = false;
        }
      }
    },

    onStructureSectionItemClick(structureItem, item) {
      const sections = this.structureItemsState[structureItem['uid']].sections
      sections[item.uid] = !sections[item.uid]
    },

    onCopyGamesListClick(item) {
      const games = item.games || [];
      const text = games.map((item) => item.title_en).join(', ')
      try {
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard.writeText(text)
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";

          document.body.prepend(textArea);
          textArea.select();
          try {
            document.execCommand('copy');
          } catch (error) {
            console.error(error);
            throw error;
          } finally {
            textArea.remove();
          }
        }
        alert(`${games.length} game(s) copied to clipboard`)
      } catch (error) {
        alert(`Error: unable to copy games to clipboard`)
      }
    },

    onArrowClick() {
      window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
    }
  }
}
</script>