<template>
  <div class="page-slider">
    <div class="page-slider__view">
      <div class="page-slider__slider _swiper" ref="swiper">
        <div class="swiper-wrapper">
          <div class="page-slider__slide swiper-slide" v-for="item of games">
            <component
                :is="sliderCardComponent"
                :item="item"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page-slider__controls">
      <!-- <div class="page-slider__see-more">
        <a href="/games" class="page-button">
          <div></div><div>view all</div><div></div>
        </a>
        <p>Find out more about all our games</p>
      </div> -->
      <div class="page-slider__nav">
        <div class="page-slider__progressbar">
          <div class="page-slider-progressbar" ref="progressbar"></div>
        </div>
        <div class="page-slider__buttons">
          <div class="page-slider__prev-button page-slider-prev _icon-arrow-1"
               ref="prevSlideButton"></div>
          <div class="page-slider__next-button page-slider-next _icon-arrow-1"
               ref="nextSlideButton"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Swiper from '@/assets/js/Swiper';

export default {
    name: "GamesSlider",
    props: {
        games: Array,
        sliderCardComponent: Object
    },

    components: {},

    data() {
        return {
            swiper: null
        }
    },

    watch: {
        games(value) {
            if ( value.length > 0 ) {
                setTimeout(() => {
                    this.initSlider()
                }, 0)
            }
        }
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        initSlider() {
            if (this.swiper) return;
            this.swiper = new Swiper(this.$refs.swiper, {
                observer: true,
                speed: 900,
                observeParents: true,
                slidesPerView: 1.3,
                // autoHeight: true,
                grabCursor: true,
                slideActiveClass: '_active-slide',
                navigation: {
                    prevEl: this.$refs.prevSlideButton,
                    nextEl: this.$refs.nextSlideButton,
                    disabledClass: '_disabled',
                },
                pagination: {
                    el: this.$refs.progressbar,
                    type: 'progressbar',
                },

                breakpoints: {
                    320: {
                        spaceBetween: 10,
                        slidesPerView: 2.5,
                    },
                    400: {
                        spaceBetween: 10,
                        slidesPerView: 3,
                    },
                    480: {
                        spaceBetween: 10,
                        slidesPerView: 3.8,
                    },
                    560: {
                        spaceBetween: 10,
                        slidesPerView: 4,
                    },
                    780: {
                        spaceBetween: 10,
                        slidesPerView: 5,
                    },
                }
            });

        }
    }
}
</script>


